import Vue from 'vue'
import SurveyRender from './SurveyRender.vue'
import {getDocumentData} from '../client'

const data = getDocumentData("survey-data");

if (document.getElementById("survey")) {
    new Vue({
        render: h => h(SurveyRender, {
            props: data
        })
    }).$mount('#survey');
}
